<template>
  <vue-context ref="menu" :close-on-click="true" :close-on-scroll="true" @open="openContext" v-if="enable">
      <li>
        <a class="context-item-delete" href="#" @click.prevent="clickContext($event, 'delete')">
          <font-awesome-icon :icon="{ prefix: 'fas', iconName: 'trash-alt', }" class="icon btn-icon" /> Eliminar
        </a>
      </li>
    </vue-context>
</template>
<script>
import VueContext from 'vue-context';
import 'vue-context/dist/css/vue-context.css';

export default {
  name: 'ContextMenu',
  components: { VueContext },
  props: [
    'enable',
  ],
  data() {
    return {
      selectedContextData: {},
    };
  },
  methods: {
    openContext(event, data) {
      this.selectedContextData = data;
    },
    clickContext(event, action) {
      this.$emit('clickContext', { event, data: this.selectedContextData, action });
    },
  },
};
</script>
<style lang="scss" scoped>

</style>
