<template>
  <div>

    <div class="row">
      <div class="col-12 mb-3">
        <transition name="slide-fade" appear>
          <a class="btn btn-link btn-border mr-2" title="Regresar a pantalla anterior" @click="goBack()" v-b-popover.hover.bottom.noninteractive>
            <font-awesome-icon :icon="{ prefix: 'fa', iconName: 'arrow-left', }" class="icon btn-icon" />
          </a>
        </transition>
        <transition name="slide-fade" appear>
          <a class="btn btn-link btn-border mr-2" @click="openModalOrden()" v-if="$agregar(['Ordenes'])">
            <font-awesome-icon :icon="{ prefix: 'fa', iconName: 'plus', }" class="icon btn-icon" /> NUEVA ORDEN
          </a>
        </transition>
      </div>
    </div>

    <transition name="slide-fade" appear>
      <div class="row">
        <div class="col-12 mt-2">
          <div class="table-responsive site-table2-responsive">
            <h6 v-if="!es_proveedor && NomProv">Proveedor: {{ NomProv }}</h6>
            <h6 v-if="es_proveedor && NomProv">{{ NomProv }}</h6>
            <vue-good-table
            styleClass="vgt-table condensed site-table2"
            :columns="columns"
            :rows="rows"
            :search-options="{
              enabled: true,
              trigger: 'enter',
              skipDiacritics: true,
              placeholder: 'Buscar en datos de Orden',
            }">
              <div slot="emptystate">
                <div class="empty-image">
                  <img src="@/assets/empty.svg" alt="Empty">
                  <p class="empty-description">Sin datos</p>
                </div>
              </div>
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == 'Validos'" class="pointer"
                  v-b-popover.hover.html="`<strong>Progreso: </strong>${props.row.Validos}%<br>El Porcentaje de progreso esta en función de los documentos sin marca de riesgo hasta el periodo ${props.row.MaxPeriodo} y los meses previos`">
                  <bar-progress :value="props.row.Validos"></bar-progress>
                </span>
                <span v-else-if="props.column.field == 'Acciones'">
                  <b-button class="btn-detail mr-1" @click="abrirOrden(props.row.OrdenId)">
                    <font-awesome-icon :icon="{ prefix: 'fa', iconName: 'list-alt', }" class="icon btn-icon"/> Ver detalles</b-button>
                  <b-button class="btn-detail" v-if="$eliminar(['Ordenes'])"
                    @click="showDeleteOrden(props.row.OrdenId)"
                    v-b-popover.hover="'Eliminar archivo'">
                    <font-awesome-icon v-if="loadingDel" :icon="{ prefix: 'fas', iconName: 'spinner', }" class="icon btn-icon" spin/>
                    <font-awesome-icon v-if="!loadingDel" :icon="{ prefix: 'fa', iconName: 'trash-alt', }" class="icon btn-icon"/>
                  </b-button>
                </span>
                <span v-else-if="props.column.field == 'Estatus'">
                  <b-badge class="font-size-12" :variant="props.row.Estatus === 1 ? 'success' : 'secondary'">{{ props.row.Estatus === 1 ? 'Activa' : 'Cerrada' }}</b-badge>
                </span>
                <span v-else class="ellipsis-300">
                  {{props.formattedRow[props.column.field]}}
                </span>
              </template>
            </vue-good-table>
          </div>
        </div>
      </div>
    </transition>

    <context-menu ref="contextmenu" :enable="$eliminar(['Ordenes'])" @clickContext="clickContext($event)"/>

    <b-modal
      id="bv-modal-orden" size="md" centered hide-footer content-class="site-modal"
      :header-text-variant="'light'"
      v-if="$agregar(['Ordenes'])">
      <template #modal-title>
        ALTA DE NUEVA ORDEN
      </template>
      <form class="form-site inner" @submit.prevent="guardarOrden()">
        <div class="form-row form-row-select">
          <div class="form-holder" :class="{error: validation.hasError('folioOrden')}">
            <fieldset>
              <legend>Orden del Contratante</legend>
              <input type="text" class="form-control" placeholder="Folio de Orden" v-model="folioOrden">
              <span class="icon" :title="validation.firstError('folioOrden')" v-if="validation.hasError('folioOrden')">
                <font-awesome-icon :icon="{ prefix: 'fa', iconName: 'exclamation-circle' }" class="icon"/>
              </span>
            </fieldset>
          </div>
          <div class="form-holder">
            <fieldset :class="validation.hasError('fechaOrden') ? 'select-error' : ''">
              <legend>Fecha Orden</legend>
              <date-picker
                type="date"
                :placeholder="'DD-MM-AAAA'"
                :clearable="false"
                :format="'MM-YYYY'"
                :formatter="formatedFechaOrden"
                v-model="fechaOrden">
                <template v-slot:header="{ emit }">
                  <button class="mx-btn mx-btn-text" @click="emit(new Date())">* HOY</button>
                </template>
              </date-picker>
            </fieldset>
          </div>
        </div>
        <div class="form-row form-row-select">
          <div class="form-holder">
            <fieldset :class="validation.hasError('fechaIniProy') ? 'select-error' : ''">
              <legend>Fecha Inicial del Proyecto</legend>
              <date-picker
                type="date"
                :placeholder="'DD-MM-AAAA'"
                :clearable="false"
                :format="'MM-YYYY'"
                :formatter="formatedFechaOrden"
                v-model="fechaIniProy">
                <template v-slot:header="{ emit }">
                  <button class="mx-btn mx-btn-text" @click="emit(new Date())">* HOY</button>
                </template>
              </date-picker>
            </fieldset>
          </div>
          <div class="form-holder">
            <fieldset :class="validation.hasError('fechaFinProy') ? 'select-error' : ''">
              <legend>Fecha Final del Proyecto</legend>
              <date-picker
                type="date"
                :placeholder="'DD-MM-AAAA'"
                :clearable="false"
                :format="'MM-YYYY'"
                :formatter="formatedFechaOrden"
                v-model="fechaFinProy">
                <template v-slot:header="{ emit }">
                  <button class="mx-btn mx-btn-text" @click="emit(new Date())">* HOY</button>
                </template>
              </date-picker>
            </fieldset>
          </div>
        </div>
        <div class="form-row form-row-select">
          <div class="form-holder form-holder-2">
            <fieldset :class="validation.hasError('idEmpresa') ? 'select-error' : ''">
              <legend>Empresa contratante</legend>
              <v-select
                class="form-select"
                placeholder="Empresa"
                label="Nombre"
                :options="empresas"
                :reduce="empresa => empresa.EmpresaId"
                :searchable="false"
                :clearable="false"
                v-model="idEmpresa"></v-select>
            </fieldset>
          </div>
        </div>
        <VueFileAgent
          class="dropzone"
          :theme="'list'"
          :multiple="false"
          :deletable="true"
          :meta="true"
          :accept="'application/pdf,.pdf'"
          :maxSize="'50MB'"
          :maxFiles="1"
          :helpText="'SELECCIONE ARCHIVO A SUBIR'"
          :errorText="{
            type: 'Archivo no permitido, solo se admiten PDF´s',
            size: 'El archivo no debe exceder los 50MB',
          }"
          @select="filesSelected($event)"
          @beforedelete="onBeforeDelete($event)"
          v-model="fileOrden"
        ></VueFileAgent>
        <b-alert show variant="danger" v-if="fileError">{{ fileError }}</b-alert>
        <button type="submit" class="btn btn-block btn-action mb-2" block>
          <font-awesome-icon v-if="loading" :icon="{ prefix: 'fas', iconName: 'spinner', }" class="icon btn-icon" spin/>
          <font-awesome-icon v-if="!loading" :icon="{ prefix: 'fa', iconName: 'save', }" class="icon btn-icon" />Guardar
        </button>
      </form>
    </b-modal>

  </div>
</template>
<script>
/* eslint-disable object-curly-newline */
// eslint-disable-next-line import/no-cycle
import apiClient from '@/services/axios';
import { VueGoodTable } from 'vue-good-table';
import 'vue-good-table/dist/vue-good-table.css';
import ContextMenu from '@/components/ContextMenu.vue';
import SimpleVueValidation from 'simple-vue-validator';
import BarProgress from '@/components/BarProgress.vue';
import moment from 'moment';

const { Validator } = SimpleVueValidation;

export default {
  components: {
    VueGoodTable,
    ContextMenu,
    BarProgress,
  },
  props: [
    'ProvId',
    'PrevRoute',
    'NomProv',
    'es_proveedor',
  ],
  data() {
    return {
      loading: false,
      loadingDel: false,
      folioOrden: '',
      fileOrden: [],
      fileError: false,
      idEmpresa: '',
      empresas: [],
      periodoDeConsulta: this.$formatDateMonth(moment().subtract(1, 'M').toDate()),
      fechaOrden: null,
      fechaIniProy: null,
      fechaFinProy: null,
      formatedFechaOrden: {
        stringify: (date) => this.$formatDateMX(date) || '',
      },
      columns: [
        {
          label: '#',
          field: 'OrdenId',
        },
        {
          label: 'Folio de orden',
          field: 'Folio',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Fecha de Orden',
          field: 'Fecha',
          dateInputFormat: 'dd-mm-yyyy',
          dateOutputFormat: 'yyyy-mm-dd',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Contratante',
          field: 'Empresa',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Estatus',
          field: 'Estatus',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Fecha de Carga',
          field: 'FechaCarga',
          type: 'created',
          dateInputFormat: 'dd-mm-yyyy',
          dateOutputFormat: 'yyyy-mm-dd',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'MaxPeriodo',
          field: 'MaxPeriodo',
          hidden: true,
        },
        {
          label: 'Progreso',
          field: 'Validos',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Acciones',
          field: 'Acciones',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
          width: '150px',
        },
      ],
      rows: [],
    };
  },
  validators: {
    folioOrden(value) { return Validator.value(value).required('Campo obligatorio').lengthBetween(1, 30, 'Máximo 30 caracteres'); },
    idEmpresa(value) { return Validator.value(value).required('Campo obligatorio').digit().length(1); },
    fechaOrden(value) { return Validator.value(value).required('Campo obligatorio'); },
    fechaIniProy(value) { return Validator.value(value).required('Campo obligatorio'); },
    fechaFinProy(value) { return Validator.value(value).required('Campo obligatorio'); },
  },
  mounted() {
    this.getOrdenes();
    this.getEmpresas();
  },
  methods: {
    getOrdenes() {
      apiClient.get('/ordenes', {
        params: {
          ProvId: this.ProvId,
        },
      })
        .then((res) => {
          this.rows = res.data;
        })
        .catch(() => {});
    },
    getEmpresas() {
      apiClient.get('/empresas', {
        params: {
          repse: 1,
        },
      })
        .then((res) => {
          this.empresas = res.data;
        })
        .catch(() => {});
    },
    openModalOrden() {
      this.fileOrden = [];
      this.fileError = '';
      this.folioOrden = '';
      this.idEmpresa = '';
      this.fechaOrden = null;
      this.fechaIniProy = null;
      this.fechaFinProy = null;
      this.$bvModal.show('bv-modal-orden');
    },
    filesSelected(files) {
      const { error } = files[0];
      if (error.type) { this.fileError = 'Archivo no permitido, solo se admiten PDF´s.'; }
      if (error.size) { this.fileError = 'El archivo no debe exceder los 50MB.'; }
      if (!error) { this.fileError = ''; }

      const validFileRecords = files.filter((fileRecord) => !fileRecord.error);
      this.fileOrden = validFileRecords;
    },
    onBeforeDelete() {
      this.fileOrden = [];
    },
    guardarOrden() {
      this.$validate().then((success) => {
        if (success) {
          if (this.fileOrden[0]) {
            this.loading = true;
            // Formulario para envio de archivo con data
            const formData = new FormData();
            formData.append('File', this.fileOrden[0].file);
            formData.append('ProvId', this.ProvId);
            formData.append('Folio', this.folioOrden);
            formData.append('Fecha', this.$formatDate(this.fechaOrden));
            formData.append('FechaIniProy', this.$formatDate(this.fechaIniProy));
            formData.append('FechaFinProy', this.$formatDate(this.fechaFinProy));
            formData.append('IdEmp', this.idEmpresa);

            apiClient.post('/ordenes', formData, {
              headers: {
                'Content-Type': 'multipart/form-data;',
              },
            })
              .then(() => {
                this.fileOrden = [];
                this.folioOrden = '';
                this.idEmpresa = '';
                this.getOrdenes();
                this.$bvModal.hide('bv-modal-orden');
                this.loading = false;
              })
              .catch(() => {
                this.loading = false;
              });
          } else {
            this.fileError = 'Se requiere el Archivo PDF de la Orden.';
          }
        }
      });
    },
    deleteOrden(OrdenId) {
      this.loadingDel = true;
      apiClient.delete('/ordenes', {
        data: {
          ProvId: this.ProvId,
          OrdenId,
        },
      })
        .then(() => {
          this.loadingDel = false;
          this.getOrdenes();
        })
        .catch(() => {
          this.loadingDel = false;
        });
    },
    showDeleteOrden(id) {
      this.$bvModal.msgBoxConfirm('Si continua, se eliminará la Orden y el archivo de la Orden adjunta. Los archivos mensuales se conservarán.', {
        title: 'Eliminar Orden',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Si, Eliminar',
        cancelTitle: 'Cancelar',
        headerClass: 'text-light',
        footerClass: 'bg-light p-2',
        hideHeaderClose: true,
        centered: true,
      })
        .then((confirm) => {
          if (confirm) this.deleteOrden(id);
        })
        .catch(() => {});
    },
    abrirOrden(ordenId) {
      this.$router.push({ name: '/ordenes/detalle', params: { ProvId: `${this.ProvId}`, OrdenId: `${ordenId}` } }).catch(() => {});
    },
    goBack() {
      this.$router.push({ name: `${this.PrevRoute ? this.PrevRoute : '/perfil'}`, params: { ProvId: `${this.ProvId}` } }).catch(() => {});
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
